import { IPlan, PlansStore } from "./types";
import { useQuery } from "@common/utils/use-query";
import { formatPlansData } from "./utils";

export const plansStore: PlansStore = {
  useGetPlans({ enabled } = { enabled: true }) {
    const res = useQuery<IPlan[]>(
      { url: "plan" },
      {
        enabled,
        refetchInterval: 1000 * 60 * 60 * 24, // reload every 24 hours
      },
    );

    if (res.data) {
      res.data = formatPlansData(res.data);
    }

    return res;
  },

  useGetPlan(alias, { enabled } = { enabled: true }) {
    return useQuery({ url: `plan/${alias}` }, { enabled });
  },
};
