import { isProduction } from "@common/utils/config";
import {
  FEATURE_FLAG_ALLOW_NO_CC_TYPE,
  FEATURE_FLAG_CONNECTIONS_TYPE,
  FEATURE_FLAG_MAGIC_LOGIN_LINKS_TYPE,
  REGION_US_EAST_1_VALUE,
  REGION_US_WEST_2_VALUE,
} from "./constants";
import { IConfig } from "./types";

export function getDefaultRegion(): string {
  return isProduction() ? REGION_US_EAST_1_VALUE : REGION_US_WEST_2_VALUE;
}

export function getCheckoutWithoutCC({
  feature_flags,
}: Pick<IConfig, "feature_flags">): boolean {
  return (
    feature_flags?.find(({ id }) => id === FEATURE_FLAG_ALLOW_NO_CC_TYPE)
      ?.value || false
  );
}

export function areMagicLinksAllowed({
  feature_flags,
}: Pick<IConfig, "feature_flags">): boolean {
  return (
    feature_flags?.find(({ id }) => id === FEATURE_FLAG_MAGIC_LOGIN_LINKS_TYPE)
      ?.value || false
  );
}

export function areConnectionsAllowed({
  feature_flags,
}: Pick<IConfig, "feature_flags">): boolean {
  return (
    feature_flags?.find(({ id }) => id === FEATURE_FLAG_CONNECTIONS_TYPE)
      ?.value || false
  );
}
