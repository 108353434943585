import { createContext, PropsWithChildren, ReactElement, useMemo } from "react";
import { plansStore } from "./store";
import { IPlansContext } from "./types";
import { PARENT_PLANS } from "./constants";

export const PlansContext = createContext<IPlansContext>({
  plans: [],
  plansHierarchy: [],
});

export function PlansProvider({ children }: PropsWithChildren): ReactElement {
  const { isLoading, error, data: plans = [] } = plansStore.useGetPlans();

  const plansHierarchy = useMemo(
    () =>
      PARENT_PLANS.map((parentPlanObj) => ({
        ...parentPlanObj,
        plans: plans.filter(
          (planObj) =>
            planObj.alias.includes(parentPlanObj.type) &&
            !planObj.alias.includes("-with-dwh"),
        ),
      })),
    [plans],
  );

  if (isLoading || error || !plans.length) {
    return <></>;
  }

  return (
    <PlansContext.Provider value={{ plans, plansHierarchy }}>
      {children}
    </PlansContext.Provider>
  );
}
