import { captureException } from "@sentry/nextjs";
import { IBasePlan, IPlan } from "./types";
import { PLAN_ALIAS_BUSINESS, PLAN_EXTRAS } from "./constants";

// TEMP
// Prices from the API are formatted inconsistently
// We need to convert them to float
export function convertToNumber(value: string | number): number {
  if (typeof value === "string") {
    return parseFloat(value.replace("$", "").replace(",", ""));
  }

  return value;
}

export function formatPlansData(data: IBasePlan[]): IPlan[] {
  return (
    data
      // only available plans
      ?.filter(({ can_purchase }) => can_purchase)
      .filter(({ alias }) => String(alias) !== PLAN_ALIAS_BUSINESS)
      // populate extra content
      // not present (yet) in the API response
      .map((planObj) => {
        const { alias } = planObj;
        if (PLAN_EXTRAS[alias]) {
          return { ...planObj, ...PLAN_EXTRAS[alias] };
        }

        return planObj as IPlan;
      })
      // convert price to number
      .map((obj) => ({
        ...obj,
        per_user_price: convertToNumber(obj.per_user_price),
        price: convertToNumber(obj.price),
      }))
  );
}

export function isMonthlyPlan({
  billing_period_months,
}: Pick<IBasePlan, "billing_period_months">): boolean {
  return !!(billing_period_months === 1);
}

export function isAnnualPlan({
  billing_period_months,
}: Pick<IBasePlan, "billing_period_months">): boolean {
  return !!(billing_period_months === 12);
}

export function getBillingFrequencyCopy({
  billing_period_months,
}: Pick<IBasePlan, "billing_period_months">): string {
  if (billing_period_months === 1) {
    return "Monthly Billing";
  } else if (billing_period_months === 12) {
    return "Annual Billing";
  }

  captureException(
    new Error(
      `getBillingFrequencyCopy error: unknown billing frequency of "${billing_period_months}"`,
    ),
  );
  return "Unknown Billing Frequency";
}

export function formatPlanName(name: string): string {
  return name.replace("Metabase", "").trim();
}
